<template>
  <div>
    <c-search-box @enter="getDeptList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 부서 -->
          <c-dept type="search" label="LBLDEPT" name="deptCd" :plantCd="searchParam.plantCd" v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <!-- 부서목록 -->
        <c-tree-table
          title="LBL0003215"
          parentProperty="upDeptCd"
          customID="deptCd"
          :columns="gridDept.columns"
          :data="gridDept.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          @rowClick="rowClickDept"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getDeptList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
        <c-card class="cardClassDetailInfo" :noHeader="true" topClass="topcolor-lightblue">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
              <!-- 부서별 공정 목록 -->
              <c-table
                ref="processTable"
                title="LBL0003268"
                :columns="gridProcess.columns"
                :data="gridProcess.data"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :expandAll="true"
                topBorderClass="topcolor-lightblue"
                gridHeight="350px"
                @linkClick="rowClickProcess"
              >
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
              <!-- 부서별 설비 목록 -->
              <c-table
                ref="gridEquip"
                title="LBL0003269"
                tableId="gridEquip"
                :columns="gridEquip.columns"
                :data="gridEquip.data"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :expandAll="true"
                topBorderClass="topcolor-lightblue"
                gridHeight="350px"
                @linkClick="linkEquipClick"
              >
              </c-table>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!-- 부서별 화학자재 목록 -->
              <c-table
                ref="gridChem"
                title="LBL0003270"
                tableId="gridChem"
                :columns="gridChem.columns"
                :data="gridChem.data"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :expandAll="true"
                topBorderClass="topcolor-lightblue"
                gridHeight="350px"
                @linkClick="linkChemClick"
              >
              </c-table>
            </div>
            <c-dialog :param="popupOptions"></c-dialog>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-manage',
  data() {
    return {
      listDeptUrl: '',
      listProcessUrl: '',
      listEquipUrl: '',
      listChemUrl: '',
      editable: true,
      searchParam: {
        plantCd: null,
        deptCd: '',
        useFlag: 'Y',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      gridDept: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridProcess: {
        columns: [
          {
            name: 'upProcessName',
            field: 'upProcessName',
            // 상위공정
            label: 'LBL0001700',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            // 단위공정
            label: 'LBL0001701',
            type: "link",
            align: 'left',
            sortable: true,
          },
          {
            name: 'processCd',
            field: 'processCd',
            // 공정코드
            label: 'LBL0003233',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridEquip: {
        columns: [
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            // 설비코드
            label: 'LBL0003271',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0001725',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0001720',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            // 단위공정
            label: 'LBL0001701',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridChem: {
        columns: [
          {
            name: 'mdmChemMaterialId',
            field: 'mdmChemMaterialId',
            // 화학자재 번호
            label: 'LBL0003272',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재명
            label: 'LBL0003273',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            // 성상
            label: 'LBL0003274',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'mixFlagName',
            field: 'mixFlagName',
            // 물질구성
            label: 'LBL0003275',
            align: 'center',
            style: 'width:45px',
            sortable: true,
          },
          {
            name: 'equipment_names',
            field: 'equipment_names',
            // 관련설비
            label: 'LBL0003276',
            align: 'left',
            sortable: true,
          },
          {
            name: 'existRegulation',
            field: 'existRegulation',
            // 규제포함
            label: 'LBL0003277',
            align: 'center',
            type: 'color',
            style: 'width:70px',
            sortable: true,
          }
        ],
        data: [],
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listDeptUrl = selectConfig.mdm.dept.list.url;
      this.listProcessUrl = selectConfig.mdm.process.list.url;
      this.listEquipUrl = selectConfig.mdm.equipment.list.url;
      this.listChemUrl = selectConfig.mdm.mam.chem.listreg.url + '/equip';
      this.getDeptList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    rowClickProcess(row, col) {
      if (col && col.name === 'upProcessName') {
        this.popupOptions.title = '제조공정'; // 상위공정 정보
        this.popupOptions.param = {
          processType: 'P', // P: 상위공정, C: 단위공정
          processCd: row.upProcessCd, // 공정코드
        };
      } else if (col && col.name === 'processName') {
        this.popupOptions.title = '공정절차(작업절차)'; // 단위공정 정보
        this.popupOptions.param = {
          processType: 'C', // P: 상위공정, C: 단위공정
          processCd: row.processCd, // 공정코드
        };
      }
      this.popupOptions.target = () => import(`${'@/pages/common/process/processInfoPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },

    getDeptList() {
      this.getListDept();
      this.reset();
    },
    getListDept() {
      this.$http.url = this.listDeptUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridDept.data = _result.data;
      },);
    },
    rowClickDept(row) {
      this.getProcessList(row.deptCd)
      this.getEquipList(row.deptCd)
      this.getChemList(row.deptCd)
    },
    getProcessList(_deptCd) {
      this.$http.url = this.listProcessUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
        useFlag: 'Y',
        // processLevelCd: '20',
      };
      this.$http.request((_result) => {
        this.gridProcess.data = _result.data;
      },);
    },
    getEquipList(_deptCd) {
      this.$http.url = this.listEquipUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
        discardFlag: 'N',
      };
      this.$http.request((_result) => {
        this.gridEquip.data = _result.data;
      },);
    },
    getChemList(_deptCd) {
      this.$http.url = this.listChemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: _deptCd,
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.gridChem.data = _result.data;
      },);
    },
    linkEquipClick(row) {
      this.popupOptions.title = 'LBL0001728'; // 설비 상세
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        psmFlag: row ? row.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'../fim/equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkChemClick(row) {
      this.popupOptions.title = 'LBL0003279'; // 화학자재 상세
      this.popupOptions.param = {
        mdmChemMaterialId: row.mdmChemMaterialId,
      };
      this.popupOptions.target = () => import(`${'../mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    reset() {
      this.rowRemoveSelect();
      this.gridProcess.data = [];
      this.gridEquip.data = [];
      this.gridChem.data = [];
    },
  }
};
</script>
